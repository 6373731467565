// ==================================================
// Tabs and actions links
// ==================================================

#block-tabs,
#block-action-links {
  background-color: #efebe3;
  text-align: right;
  ul {
    @include rem(font-size, 0);
    margin: 20px 0;
    padding: 0;
    li {
      @include rem(font-size, 12px);
      display: inline-block;
      margin: 0 0 0 5px;
      padding: 0;
      text-transform: uppercase;
      &:before {
        display: none; }
      &.active,
      &:hover {
        a {
          background-color: map-get($colors, primary);
          color: #fff; } }
      a {
        background-color: #fff;
        color: map-get($colors, primary);
        display: block;
        padding: 8px 15px;
        text-decoration: none;
        transition: all 0.2s ease-in-out; } } } }

.contextual-links {
  li {
    &:before {
      display: none; } } }

#admin-menu,
#imce-content {
  li {
    margin: 0;
    &:before {
      display: none; } } }
