// ==================================================
// Blocks
// ==================================================

.block {
  @extend .clearfix;
  .title {
    h2 {
      @include rem(font-size, 30px);
      .icon {
        @include rem(font-size, 30px);
        margin-right: 15px;
        vertical-align: middle; } } }
  .content {
    @include rem(font-size, 14px);
    img {
      height: auto;
      max-width: 100%;
      &.background {
        left: 50%;
        max-width: none;
        margin-left: -960px !important;
        margin-top: -540px  !important;
        position: absolute;
        top: 50%;
        z-index: -1; } } } }

.block-background {
  overflow: hidden;
  position: relative;
  z-index: 1;
  .container {
    max-height: 1080px; }
  .content {
    img {
      &.background {
        margin: 0; } } } }

.block-primary {
  background-color: map-get($colors, primary);
  .title {
    h2,
    a, span {
      color: #fff; } }
  .content {
    color: #fff;
    h2, h3, h4, h5,
    p,
    a, span,
    ul, ol, li {
      color: #fff; } } }
