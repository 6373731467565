// Big screen : >= 960px
// ==================================================
@include susy-breakpoint(960px, 24) {
  #block-recruitment-offers {
    .content {
      .views-row {
        &:nth-child(4n) {
          margin-right: 0; } } } }
  #block-medias-mediatheque {
    .content {
      .views-row {
        &:nth-child(4n) {
          margin-right: 0; } } } }

  #block-contact-informations {
    margin: 0 -475px 0 0;
    position: absolute;
    right: 50%;
    // top: 255px
    > .container {
      width: auto !important; }
    .block-inner {
      float: none !important;
      margin: 0 !important;
      padding-left: 5px;
      width: 275px !important; }
    .content {
      @include rem(font-size, 16px);
      img {
        margin: 0 0 15px 0; }
      strong {
        @include rem(font-size, 20px); } } }

  #block-contact-form {
    .content {
      .form-submit {
        margin-right: 340px; } } }

  #block-footer-secondary-menu {
    .content {
      ul {
        li {
          &.menu-copyright {
            display: inline-block; }
          &.menu-sitemap {
            display: none; } } } } }

  #block-footer-brands-menu {
    .content {
      ul {
        li {
          .mobile {
            display: none; } } } } } }

// Tablet : >= 640px and < 960px
// ==================================================
@include susy-breakpoint(640px 959px, 16) {
  .region {
    > .container {
      @include container(640px); } }

  form {
    margin: 0;
    width: 630px; }

  .form-item {
    margin: 15px 0;
    label {
      display: block;
      margin-bottom: 10px;
      text-align: left;
      width: auto; } }

  .form-text {
    width: 100%; }

  .form-textarea {
    width: 100%; }

  .form-actions {
    text-align: right;
    margin: 15px 0; }

  .form-submit:not(.ajax-processed) {
    float: none;
    margin: 0; }

  .description {
    margin-left: 0; }

  .node-type-news {
    #block-system-main {
      .block-inner {
        padding-right: 0; } }
    .field-name-field-news-picture {
      img {
        position: relative; } } }

  .g-recaptcha {
    margin-left: 0; }

  .node-type-offer {
    .node {
      .content {
        margin-left: 0;
        width: 390px; } } }

  #main {
    .block {
      > .container {
        @include container(640px); }
      .block-inner {
        @include span(16); } } }

  #page {
    box-shadow: -2px 0 10px rgba(#000, 0.3); }

  #wrapper {
    margin-bottom: 0;
    &:after {
      display: none; } }

  #offcanvas-menu {
    display: block; }

  #block-header-site-slogan {
    @include span(9 last);
    .title {
      p {
        @include rem(font-size, 26px); } } }

  #block-header-langs {
    display: none; }

  #menu {
    display: none; }

  #block-system-main {
    html.page-node-5 &,
    html.page-node-12 & {
      .block-inner {
        padding-left: 0;
        padding-right: 0; } } }

  #block-home-group {
    position: relative;
    &:after {
      bottom: 10px;
      top: auto; }
    .block-inner {
      #main & {
        @include span(16);
        position: inherit;
        width: auto; } } }

  #block-home-news {
    .block-inner {
      height: auto;
      #main & {
        @include span(16); } }
    .content {
      img {
        max-width: none;
        width: 100%; } } }

  #block-home-newsletter {
    .block-inner {
      #main & {
        @include span(16); } } }

  #block-home-expertise-vision {
    .container {
      height: auto; }
    .content {
      ul {
        li {
          margin: 0;
          width: auto;
          h3 {
            margin: 30px 0; }
          p {
            &.text-summary {
              height: auto; } } } }
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-home-brands {
    .block-inner {
      padding-left: 0; }
    .content {
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-home-recruitment {
    .content {
      ul {
        &.links {
          li {
            &:last-child {
              display: none; } } } } } }

  #block-group-expertise-vision {
    .block-inner {
      padding-left: 240px; } }

  #block-group-history {
    .container {
      height: auto; }
    .block-inner {
      padding-right: 0; }
    .content {
      ul {
        li {
          > span {
            width: 510px; } } }
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-group-statistics {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 200px; } }

  #block-group-dna {
    .container {
      height: auto; }
    .block-inner {
      padding-right: 200px; }
    .content {
      p {
        @include rem(line-height, 20px); } } }

  #block-brands-gavottes {
    .block-inner {
      padding-right: 200px; } }

  #block-brands-traoumad {
    .block-inner {
      padding-left: 200px; } }

  #block-brands-expertise {
    .block-inner {
      padding-left: 40px;
      padding-right: 40px; } }

  #block-find-distribution {
    .block-inner {
      padding-right: 200px; } }

  #block-find-stores {
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .title {
      margin-left: 0; }
    .content {
      ul {
        li {
          > span {
            width: 390px; } } } } }

  #block-find-international {
    .block-inner {
      padding-right: 200px; } }

  #block-commitments-sites {
    .block-inner {
      padding-right: 200px; }
    .title {
      h2 {
        margin: 30px 0; } } }

  #block-commitments-products {
    .block-inner {
      padding-left: 200px; }
    .title {
      h2 {
        margin: 30px 0; } } }

  #block-commitments-packages {
    .block-inner {
      padding-right: 200px; }
    .title {
      h2 {
        margin: 30px 0; } } }

  #block-recruitment-policies {
    .block-inner {
      padding-right: 200px; }
    .title {
      h2 {
        margin: 30px 0; } } }

  #block-recruitment-offers {
    .content {
      .views-row {
        width: 310px;
        &:nth-child(2n) {
          margin-right: 0; } } } }

  #block-recruitment-spontaneous {
    display: none; }

  #block-medias-press {
    .container {
      min-height: 750px; }
    .content {
      .owl-item {
        height: auto; }
      .views-field-field-press-file {
        bottom: auto;
        left: auto;
        position: relative; } } }

  #block-medias-newsletters {
    .content {
      .owl-item {
        max-width: 230px;
        img {
          width: 100%; } } } }

  #block-medias-newsletters-form {
    .content {
      form {
        margin: 0;
        width: auto; } } }

  #block-medias-mediatheque {
    .container {
      min-height: 680px; }
    .content {
      .views-row {
        max-width: 150px;
        &:nth-child(4n) {
          margin-right: 0; } }
      .views-exposed-widget {
        > label {
          display: none; } } } }

  #block-news {
    .content {
      .view-news {
        .views-row {
          &:nth-child(even), {
            padding-left: 40%; }
          &:nth-child(odd) {
            padding-right: 40%; } }
        .item-list {
          @include container(640px); } } } }

  #block-recruitment-jobform {
    display: none; }

  #footer {
    &:before {
      display: none; }
    > .container {
      background: none;
      min-height: 0;
      width: auto; } }

  #block-footer-main-menu {
    display: none; }

  #block-footer-secondary-menu {
    float: none;
    margin: 0;
    width: auto;
    .content {
      ul {
        li {
          border-bottom: 2px solid map-get($colors, primary);
          display: block;
          font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif;
          &.last {
            border-bottom: 0; }
          &:before {
            display: none; }
          a {
            @include rem(font-size, 16px);
            @include rem(line-height, 65px);
            display: block;
            padding: 0 50px 0 10px;
            position: relative;
            &:before {
              font-family: "locma";
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: map-get($colors, secondary);
              content: "\e602";
              margin-top: -8px;
              position: absolute;
              right: 10px;
              top: 50%; }
            .text {
              display: inline-block;
              line-height: normal;
              vertical-align: middle; } } } } } }

  #block-footer-brands-menu {
    float: none;
    margin: 0;
    width: auto;
    .content {
      ul {
        li {
          border-top: 2px solid map-get($colors, primary);
          display: block;
          font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif;
          margin: 0;
          text-align: left;
          text-transform: none;
          width: auto;
          &:before {
            display: none; }
          .desktop {
            display: none; }
          a {
            @include rem(font-size, 16px);
            @include rem(line-height, 65px);
            color: map-get($colors, primary);
            display: block;
            padding: 0 50px 0 10px;
            position: relative;
            &:before {
              font-family: "locma";
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: map-get($colors, secondary);
              content: "\e602";
              margin-top: -8px;
              position: absolute;
              right: 10px;
              top: 50%; }
            .text {
              display: inline-block;
              line-height: normal;
              vertical-align: middle; } } } } } } }

// Mobile : < 640px
// ==================================================
@include susy-breakpoint(320px 639px, 8) {
  .region {
    > .container {
      @include container(320px); } }

  form {
    margin: 0;
    width: 310px; }

  .form-item {
    margin: 15px 0;
    label {
      display: block;
      margin-bottom: 10px;
      text-align: left;
      width: auto; } }

  .form-text {
    width: 100%; }

  .form-textarea {
    width: 100%; }

  .form-actions {
    text-align: right;
    margin: 15px 0; }

  .form-submit:not(.ajax-processed) {
    float: none;
    margin: 0; }

  .description {
    margin-left: 0; }

  .webform-component-markup {
    p {
      width: auto; } }

  .g-recaptcha {
    margin-left: 0; }

  .node-type-news {
    #block-system-main {
      .block-inner {
        padding-right: 0; } }
    .field-name-field-news-picture {
      img {
        position: relative; } } }

  .node-type-offer {
    .node {
      .content {
        margin-left: 0;
        width: auto; }
      .side-content {
        margin-bottom: 30px;
        width: 100%; } } }

  #main {
    .block {
      > .container {
        @include container(320px); }
      .block-inner {
        @include span(8); } } }

  #page {
    box-shadow: -2px 0 10px rgba(#000, 0.3); }

  #wrapper {
    margin-bottom: 0;
    &:after {
      display: none; } }

  #offcanvas-menu {
    display: block; }

  #block-header-site-slogan {
    display: none; }

  #block-header-langs {
    display: none; }

  #menu {
    display: none; }

  #block-system-main {
    html.page-node-5 &,
    html.page-node-12 & {
      .block-inner {
        padding-left: 0;
        padding-right: 0; }
      .content {
        ul {
          text-align: left;
          li {
            margin: 0;
            padding: 30px 0;
            width: 100%;
            a {
              position: relative;
              &:before {
                @include rem(font-size, 14px);
                background: none;
                color: map-get($colors, primary);
                content: "\e602";
                display: block;
                font-family: "locma";
                height: auto;
                left: auto;
                margin-top: -7px;
                position: absolute;
                right: 7px;
                top: 50%;
                width: auto; } }
            .icon {
              display: inline-block;
              vertical-align: middle;
              width: 40%;
              &:before {
                margin-bottom: 0; } }
            .text {
              display: inline-block;
              vertical-align: middle;
              width: 60%; } } } } } }

  #block-home-introduction {
    .container {
      height: 230px; }
    .title {
      line-height: 230px;
      h2 {
        @include rem(font-size, 25px);
        b {
          @include rem(font-size, 23px); } } }
    .content {
      img {
        &.background {
          margin-top: -590px !important; } } } }

  #block-home-group {
    position: relative;
    &:after,
    &:before {
      width: 86%; }
    &:after {
      bottom: 10px;
      top: auto; }
    .block-inner {
      #main & {
        position: inherit;
        width: auto; } }
    .title {
      h2 {
        @include rem(font-size, 23px);
        margin: 30px 0; } } }

  #block-home-news {
    background-color: #efebe3;
    #main & {
      .container {
        margin: 0;
        width: auto; }
      .block-inner {
        float: none;
        height: auto;
        margin: 0;
        width: auto; } }
    .content {
      h3 {
        @include rem(font-size, 23px); }
      img {
        max-width: none;
        width: 100%; } } }

  #block-home-newsletter {
    background-color: #efebe3;
    .block-inner {
      #main & {
        @include span(8); } } }

  #block-home-expertise-vision {
    .container {
      height: auto; }
    .content {
      ul {
        li {
          margin: 0;
          width: auto;
          h3 {
            @include rem(font-size, 23px);
            margin: 30px 0; }
          p {
            &.text-summary {
              height: auto; } } } }
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-home-brands {
    .container {
      min-height: 0;
      height: auto; }
    .block-inner {
      padding-left: 0; }
    .title {
      h2 {
        @include rem(font-size, 23px);
        margin: 30px 0; } }
    .content {
      ul {
        li {
          padding-bottom: 30px;
          position: relative;
          h3 {
            @include rem(font-size, 16px);
            @include rem(line-height, 18px); }
          img {
            margin: 0;
            max-width: 30%; } } }
      img {
        &.background {
          display: none;
          visibility: hidden; } }
      .btn {
        @include rem(font-size, 0);
        background: none;
        left: 0;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;
        &:before {
          @include rem(font-size, 14px);
          background: none;
          color: map-get($colors, primary);
          content: "\e602";
          display: block;
          font-family: "locma";
          height: auto;
          left: auto;
          margin-top: -7px;
          position: absolute;
          right: 7px;
          top: 50%;
          width: auto; } } } }

  #block-home-commitments {
    .container {
      height: auto; }
    .title {
      h2 {
        @include rem(font-size, 23px);
        margin: 30px 0; } }
    .content {
      ul {
        text-align: left;
        li {
          margin: 0;
          padding: 30px 0;
          width: 100%;
          .icon {
            display: inline-block;
            vertical-align: middle;
            width: 40%;
            &:before {
              margin-bottom: 0; } }
          .text {
            @include rem(font-size, 16px);
            @include rem(line-height, 18px);
            display: inline-block;
            vertical-align: middle;
            width: 60%; }
          a {
            position: relative;
            &:before {
              @include rem(font-size, 14px);
              background: none;
              color: #fff;
              content: "\e602";
              display: block;
              font-family: "locma";
              height: auto;
              left: auto;
              margin-top: -7px;
              position: absolute;
              right: 7px;
              top: 50%;
              width: auto; } } } }
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-home-recruitment {
    .container {
      height: auto; }
    .title {
      h2 {
        @include rem(font-size, 23px);
        margin: 30px 0; } }
    .content {
      ul {
        &.links {
          margin: 30px 0;
          text-align: right;
          li {
            margin-right: 0;
            &:last-child {
              display: none; } } } } } }

  #block-group-expertise-vision {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0; }
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-group-history {
    .container {
      max-height: none;
      height: auto; }
    .block-inner {
      padding-right: 0; }
    .content {
      ul {
        li {
          > span {
            text-align: left;
            width: 230px; } } }
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-group-statistics {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0; }
    .content {
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-group-dna {
    .container {
      height: auto; }
    .block-inner {
      padding-right: 0; }
    .content {
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-brands-gavottes {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .content {
      h2 {
        margin: 30px 0; }
      img {
        margin-top: 15px;
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-brands-traoumad {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .content {
      h2 {
        margin: 30px 0; }
      img {
        margin-top: 15px;
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-brands-expertise {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      p {
        margin-left: 0;
        margin-right: 0; }
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-find-distribution {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-find-stores {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .title {
      margin-left: 0;
      h2 {
        margin: 30px 0; } }
    .content {
      ul {
        li {
          border-bottom: 1px solid map-get($colors, secondary);
          margin-bottom: 30px;
          text-align: center;
          > img {
            left: auto;
            position: relative;
            margin: 0 0 15px 0;
            max-width: 100%;
            top: auto;
            transform: none; }
          > span {
            border-left: 0;
            display: block;
            float: none;
            padding: 0;
            text-align: left;
            width: auto; }
          &:last-child {
            border-bottom: 0; } } }
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-find-international {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-commitments-sites {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-commitments-products {
    .container {
      height: auto;
      max-height: none; }
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-commitments-packages {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-recruitment-policies {
    .container {
      height: auto; }
    .block-inner {
      padding-left: 0;
      padding-right: 0; }
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      img {
        &.background {
          display: none;
          visibility: hidden; } } } }

  #block-recruitment-offers {
    background-image: none;
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      .views-row {
        margin-right: 0;
        width: 100%; } } }

  #block-recruitment-spontaneous {
    display: none; }

  #block-medias-press {
    .container {
      min-height: 750px; }
    .title {
      h2 {
        @include rem(font-size, 24px);
        margin: 30px 0; } }
    .content {
      .owl-item {
        height: auto;
        padding: 0; }
      .views-field-field-press-file {
        bottom: auto;
        left: auto;
        position: relative; } } }

  #block-medias-newsletters {
    .container {
      height: auto; }
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      .owl-item {
        max-width: 156px;
        img {
          width: 100%; } } } }

  #block-medias-newsletters-form {
    .content {
      form {
        margin: 0;
        width: 310px; }
      .form-submit {
        margin: 15px 0; }
      .description {
        margin-left: 0; } } }

  #block-medias-mediatheque {
    background-image: none;
    .container {
      min-height: 680px; }
    .title {
      h2 {
        margin: 30px 0; } }
    .content {
      .views-row {
        max-width: 150px;
        &:nth-child(2n) {
          margin-right: 0; } }
      .views-exposed-widget {
        > label {
          display: none; } } } }

  #block-contact-form {
    .content {
      .form-submit {
        margin-right: 0; } } }

  #block-contact-informations {
    .content {
      img {
        width: 100%; } } }

  #block-news {
    .content {
      .view-news {
        .views-row,
        .views-row:nth-child(even),
        .views-row:nth-child(odd) {
          height: auto;
          padding: 0 10%;
          img {
            left: auto;
            margin: 30px 0 0 0 !important;
            max-width: 100%;
            position: relative;
            right: auto; }
          h2 {
            margin: 30px 0; } }
        .item-list {
          @include container(320px); } } } }

  #block-news-newsletter {
    &:before {
      margin-left: -105px;
      width: 210px; } }

  #block-recruitment-jobform {
    display: none; }

  #footer {
    &:before {
      display: none; }
    > .container {
      background: none;
      min-height: 0;
      width: auto; } }

  #block-footer-main-menu {
    display: none; }

  #block-footer-secondary-menu {
    float: none;
    margin: 0;
    width: auto;
    .content {
      ul {
        li {
          border-bottom: 2px solid map-get($colors, primary);
          display: block;
          font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif;
          &.last {
            border-bottom: 0; }
          &:before {
            display: none; }
          a {
            @include rem(font-size, 16px);
            @include rem(line-height, 65px);
            display: block;
            padding: 0 50px 0 10px;
            position: relative;
            &:before {
              font-family: "locma";
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: map-get($colors, secondary);
              content: "\e602";
              margin-top: -8px;
              position: absolute;
              right: 10px;
              top: 50%; }
            .text {
              display: inline-block;
              line-height: normal;
              vertical-align: middle; } } } } } }

  #block-footer-brands-menu {
    float: none;
    margin: 0;
    width: auto;
    .content {
      ul {
        li {
          border-top: 2px solid map-get($colors, primary);
          display: block;
          font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif;
          margin: 0;
          text-align: left;
          text-transform: none;
          width: auto;
          &:before {
            display: none; }
          .desktop {
            display: none; }
          a {
            @include rem(font-size, 16px);
            @include rem(line-height, 65px);
            color: map-get($colors, primary);
            display: block;
            padding: 0 50px 0 10px;
            position: relative;
            &:before {
              font-family: "locma";
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: map-get($colors, secondary);
              content: "\e602";
              margin-top: -8px;
              position: absolute;
              right: 10px;
              top: 50%; }
            .text {
              display: inline-block;
              line-height: normal;
              vertical-align: middle; } } } } } } }
