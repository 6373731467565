// ==================================================
// Nodes types
// ==================================================

// Node type news
// --------------------------------------------------
.node-type-news {
  #block-system-main {
    position: relative; }
  .field-name-field-news-picture {
    img {
      float: left;
      margin: 0 10px 10px 0; } } }

// Node type offer
// --------------------------------------------------
.node-type-offer {
  .node {
    .content {
      float: left;
      margin-left: 40px;
      width: 590px; }
    .side-content {
      @include rem(font-size, 16px);
      background-color: #fef9ef;
      border: 1px solid #daba4e;
      float: right;
      height: 280px;
      margin: 0;
      position: relative;
      vertical-align: top;
      width: 230px;
      .field {
        @include rem(font-size, 12px);
        font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
        font-weight: bold;
        margin: 5px 15px;
        .field-label {
          color: map-get($colors, primary);
          display: inline-block; }
        .field-items {
          color: map-get($colors, secondary);
          display: inline-block; } }
      .field-name-field-offer-date {
        @include rem(font-size, 14px);
        color: map-get($colors, secondary);
        font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif;
        font-weight: normal;
        margin-top: 15px; }
      .field-name-title {
        @include rem(font-size, 18px);
        border-bottom: 1px solid map-get($colors, secondary);
        color: map-get($colors, primary);
        font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif;
        font-weight: normal;
        margin-bottom: 15px;
        margin-top: 15px;
        padding-bottom: 15px;
        text-transform: uppercase;
        .field-content {
          color: map-get($colors, primary); } } } } }
