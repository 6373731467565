// ==================================================
// Grid system
// http://gridcalculator.dk/#/960/24/10/5
// ==================================================

// $susy: (columns: 24, column-width: 30px, gutters: 1/3, gutter-position: split, math: static, debug: (image: show-columns, color: rgba(red, .25)))
$susy: (columns: 24, column-width: 30px, gutters: 1/3, gutter-position: split, math: static);

.region {
  > .container {
    @include container(960px); } }

#main {
  .block {
    > .container {
      @include container(960px); }
    .block-inner {
      @include span(24); } } }

#block-offcanvas-menu-toggle-menu {
  @include span(2 first); }

#block-offcanvas-menu-langs {
  @include span(2 last); }

#block-header-logo {
  @include span(7 first); }

#block-header-site-slogan {
  @include span(12); }

#block-header-langs {
  @include span(2 last); }

#block-main-menu {
  @include span(24); }

#block-breadcrumb {
  @include span(24); }

#block-home-news {
  .block-inner {
    #main & {
      @include span(8 last); } } }

#block-home-newsletter {
  .block-inner {
    #main & {
      @include span(8 last); } } }

#block-footer-main-menu {
  @include span(24); }

#block-footer-secondary-menu {
  @include span(16); }

#block-footer-brands-menu {
  @include span(8); }
