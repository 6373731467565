// ==================================================
// Icon sass file
// ==================================================

@font-face {
  font-family: "locma";
  src: url("../fonts/locma/fonts/locma.eot?nq5m6t");
  src: url("../fonts/locma/fonts/locma.eot?#iefixnq5m6t") format("embedded-opentype"), url("../fonts/locma/fonts/locma.woff?nq5m6t") format("woff"), url("../fonts/locma/fonts/locma.ttf?nq5m6t") format("truetype"), url("../fonts/locma/fonts/locma.svg?nq5m6t#locma") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"],
[class*=" icon-"],
.font-locma {
  &:before {
    font-family: "locma";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; } }

.icon-arrow-bottom:before {
  content: "\e600"; }

.icon-arrow-left:before {
  content: "\e601"; }

.icon-arrow-right:before {
  content: "\e602"; }

.icon-arrow-top:before {
  content: "\e603"; }

.icon-biscuit:before {
  content: "\e604"; }

.icon-box:before {
  content: "\e605"; }

.icon-chart:before {
  content: "\e606"; }

.icon-circle-cross:before {
  content: "\e607"; }

.icon-circle-exclamation-mark:before {
  content: "\e608"; }

.icon-circle-tick:before {
  content: "\e609"; }

.icon-clock:before {
  content: "\e60a"; }

.icon-cross:before {
  content: "\e60b"; }

.icon-dna:before {
  content: "\e60c"; }

.icon-facebook:before {
  content: "\e900"; }

.icon-factory:before {
  content: "\e60d"; }

.icon-home:before {
  content: "\e901"; }

.icon-globe:before {
  content: "\e60e"; }

.icon-magnifier:before {
  content: "\e60f"; }

.icon-mail:before {
  content: "\e610"; }

.icon-menu:before {
  content: "\e611"; }

.icon-network:before {
  content: "\e612"; }

.icon-store:before {
  content: "\e613"; }

.icon-tick:before {
  content: "\e614"; }
