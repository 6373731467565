// ==================================================
// Page
// ==================================================

*,
*:after,
*:before {
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  background-color: #fff;
  -ms-content-zooming: none;
  height: 100%;
  // overflow-x: hidden
  html.csstransforms3d &.snapjs-left,
  html.csstransforms3d &.snapjs-right {
    overflow-y: hidden; } }

// ==================================================
// Offcanvas
// ==================================================

#offcanvas-menu {
  background-color: #2f3948;
  display: none; }

#block-offcanvas-menu-toggle-menu {
  .content {
    @include rem(line-height, 34px);
    .icon {
      @include rem(font-size, 24px);
      color: map-get($colors, secondary);
      display: inline-block;
      line-height: normal;
      vertical-align: middle;
      body.snapjs-left &:before {
        content: "\e60b"; } }
    .text {
      display: none; } } }

#block-offcanvas-menu-langs {
  @include rem(line-height, 34px);
  .content {
    text-align: right;
    ul {
      @include rem(font-size, 0);
      display: inline-block;
      line-height: normal;
      margin: 0;
      vertical-align: middle;
      li {
        @include hide-text;
        background-color: #000;
        display: inline-block;
        margin: 0 0 0 10px;
        vertical-align: middle;
        &:before {
          display: none; }
        &.fr {
          a {
            background-position: 0 0; } }
        &.en {
          a {
            background-position: 0 -12px; } }
        &.active,
        &:hover {
          a {
            opacity: 1; } }
        a {
          background: #fff url("../img/flags.png") no-repeat 0 0;
          display: block;
          height: 12px;
          opacity: 0.5;
          transition: all 0.2s ease-in-out;
          width: 16px; } } } } }

#offcanvas {
  display: none;
  .panel {
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    z-index: 1; }
  .panel-left {
    background-color: map-get($colors, primary);
    color: #fff;
    height: 100%;
    left: 0;
    width: 265px;
    body.admin-menu & {
      margin-top: 30px; }
    body.admin-menu-with-shortcuts & {
      margin-top: 65px; }
    a {
      color: #fff; } } }


#block-offcanvas-main-menu {
  .content {
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        &:before {
          display: none; }
        a {
          border-bottom: 3px solid #2f3948;
          display: block;
          padding: 20px 15px;
          position: relative;
          text-decoration: none;
          &:before {
            @extend .font-locma;
            color: map-get($colors, secondary);
            content: "\e602";
            margin-top: -8px;
            position: absolute;
            right: 15px;
            top: 50%; }
          br {
            content: "";
            margin: 0 4px 0 0; } } } } } }

// ==================================================
// Page
// ==================================================

#page {
  background-color: #fff;
  position: relative;
  z-index: 2; }

// ==================================================
// Wraper
// ==================================================

#wrapper {
  height: auto !important;
  height: 100%;
  margin-bottom: -$footer_height;
  min-height: 100%;
  &:after {
    content: "";
    display: block;
    height: $footer_height; } }

// ==================================================
// Header
// ==================================================

#header {
  background-color: #efebe3;
  .container {
    position: relative; } }

#block-header-logo {
  line-height: 70px;
  .title {
    img {
      margin: 0;
      vertical-align: middle; } } }

#block-header-site-name {
  display: none; }

#block-header-site-slogan {
  line-height: 70px;
  text-align: center;
  .title {
    p {
      @include rem(font-size, 32px);
      color: map-get($colors, primary);
      display: inline-block;
      font-family: "CACPinafore Regular", Arial, Verdana, sans-serif;
      letter-spacing: 0.5px;
      line-height: normal;
      margin: 0;
      vertical-align: middle; } } }

#block-header-langs {
  line-height: 70px;
  .content {
    text-align: right;
    ul {
      @include rem(font-size, 0);
      display: inline-block;
      line-height: normal;
      margin: 0;
      vertical-align: middle;
      li {
        @include hide-text;
        background-color: #000;
        display: inline-block;
        margin: 0 0 0 10px;
        vertical-align: middle;
        &:before {
          display: none; }
        &.fr {
          a {
            background-position: 0 0; } }
        &.en {
          a {
            background-position: 0 -12px; } }
        &.active,
        &:hover {
          a {
            opacity: 1; } }
        a {
          background: #fff url("../img/flags.png") no-repeat 0 0;
          display: block;
          height: 12px;
          opacity: 0.5;
          transition: all 0.2s ease-in-out;
          width: 16px; } } } } }

// ==================================================
// Menu
// ==================================================

#menu {
  background-color: #fff; }

#block-main-menu {
  .content {
    ul {
      @include rem(font-size, 0);
      margin: 0;
      li {
        @include rem(font-size, 14px);
        display: inline-block;
        height: 65px;
        margin: 0;
        overflow: hidden;
        text-align: center;
        vertical-align: middle;
        width: 133px;
        &:before {
          display: none; }
        &.menu-contact {
          width: 152px;
          a {
            background: map-get($colors, primary) url("../img/menu-contact.png") no-repeat 0 0;
            color: #fff;
            &:after {
              opacity: 1;
              width: 136px; }
            &:before {
              @include rem(font-size, 26px);
              color: map-get($colors, secondary);
              margin-right: 20px;
              vertical-align: middle; } } } }
      a {
        display: block;
        line-height: 65px;
        position: relative;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        &.active,
        &.active-trail,
        &:hover {
          &:after {
            opacity: 1; } }
        &:after {
          background-color: map-get($colors, secondary);
          bottom: 8px;
          content: "";
          height: 2px;
          left: 8px;
          opacity: 0;
          position: absolute;
          transition: all 0.2s ease-in-out;
          width: 117px; }
        .text {
          display: inline-block;
          line-height: normal;
          vertical-align: middle; } } } } }


#block-breadcrumb {
  border-top: 1px solid #f5f4f3;
  margin-top: 15px;
  .content {
    @include rem(font-size, 11px);
    color: #cbc8c1;
    padding: 15px;
    ul {
      margin: 0;
      padding: 0; }
    li {
      color: #cbc8c1;
      display: inline;
      margin: 0 3px;
      padding: 0;
      &::before {
        display: none; }
      &.first {
        a {
          text-decoration: none; } } }
    a {
      color: #cbc8c1; } } }

// ==================================================
// Main
// ==================================================

#main {
  position: relative;
  z-index: 1; }

#block-back-button {
  .block-inner {
    padding: 30px 30px 0 0; }
  .content {
    @include rem(font-size, 0);
    text-align: right;
    a {
      @include rem(font-size, 12px);
      @include rem(line-height, 40px);
      background-color: map-get($colors, secondary);
      border-radius: 4px;
      color: #fff;
      display: inline-block;
      font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
      padding: 0 10px;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;
      html.no-touch &:hover {
        background-color: map-get($colors, primary); }
      .icon {
        margin-right: 10px;
        vertical-align: middle; }
      .text {
        display: inline-block;
        line-height: normal;
        vertical-align: middle; } } } }

#block-page-title {
  html.page-node-5 &,
  html.page-node-12 & {
    .title {
      h1 {
        border-bottom: 0;
        padding-bottom: 0;
        text-align: center; } } } }

#block-system-main {
  .container {
    min-height: 150px; }
  html.page-node-4 &,
  html.page-node-5 &,
  html.page-node-6 &,
  html.page-node-12 &,
  html.page-node-13 & {
    border-bottom: 1px solid map-get($colors, secondary);
    box-shadow: 0 10px 10px rgba(#000, 0.15);
    position: relative;
    z-index: 2; }
  html.page-node-7 &,
  html.page-node-14 &,
  html.page-node-31 &,
  html.page-node-36 &,
  html.page-node-37 &,
  html.page-node-44 &,
  html.page-node-45 &,
  html.page-node-46 & {
    .container {
      min-height: 0; } }
  html.page-node-5 &,
  html.page-node-12 & {
    .container {
      min-height: 150px; }
    .block-inner {
      padding-left: 80px;
      padding-right: 80px; }
    .content {
      ul {
        @include rem(font-size, 0);
        margin: 30px 0;
        padding: 0;
        li {
          @include rem(font-size, 16px);
          display: inline-block;
          margin: 0 45px;
          padding: 0;
          vertical-align: top;
          width: 120px;
          &:before {
            display: none; }
          a {
            display: block;
            height: 100%;
            text-decoration: none; }
          .icon {
            @include rem(font-size, 0);
            &:before {
              @include rem(font-size, 80px);
              color: map-get($colors, secondary);
              display: inline-block;
              margin-bottom: 50px; } }
          .text {
            @include rem(font-size, 18px);
            display: inline-block;
            font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif; } } } } } }

#block-home-introduction {
  overflow: hidden;
  position: relative;
  .container {
    height: 515px; }
  .title {
    line-height: 515px;
    position: relative;
    text-align: center;
    z-index: 2;
    h2 {
      @include rem(font-size, 50px);
      color: map-get($colors, primary);
      display: inline-block;
      line-height: 1;
      margin: 0;
      position: relative;
      &:after,
      &:before {
        background-color: #fff;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        margin-left: -75px;
        position: absolute;
        width: 150px; }
      &:after {
        bottom: -25px; }
      &:before {
        top: -20px; }
      span {
        color: map-get($colors, primary);
        font-family: "Pluto W01 ExtraLight", Arial, Verdana, sans-serif;
        font-weight: normal; }
      b {
        @include rem(font-size, 45px);
        color: #fff;
        font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif; } } }
  .content {
    p {
      margin: 0; }
    img {
      margin: 0; } } }

#block-home-group {
  position: relative;
  &:after,
  &:before {
    background-color: map-get($colors, secondary);
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 56%; }
  &:after {
    top: 454px; }
  &:before {
    top: 10px; }
  .block-inner {
    #main & {
      @include span(15 first);
      position: absolute;
      z-index: 1; } }
  .title {
    h2 {
      @include rem(font-size, 35px);
      margin: 50px 0; } }
  .footer {
    text-align: right;
    .btn {
      margin: 50px 0; } } }

#block-home-news {
  .block-inner {
    background-color: #efebe3;
    height: 385px; }
  .content {
    h3 {
      @include rem(font-size, 24px);
      margin: 20px; }
    p {
      @include rem(font-size, 12px);
      @include rem(line-height, 20px);
      margin: 20px; }
    a {
      text-decoration: none; }
    img {
      margin: 0; } }
  .footer {
    text-align: right;
    .btn {
      margin: 20px 0; } } }

#block-home-newsletter {
  position: relative;
  .block-inner {
    background-color: #efebe3;
    height: 80px;
    position: relative;
    &:before {
      background-color: map-get($colors, primary);
      content: "";
      display: block;
      height: 1px;
      left: 50%;
      margin-left: -20%;
      position: absolute;
      top: 0;
      width: 40%; } }
  .content {
    p {
      @include rem(font-size, 14px);
      font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
      line-height: normal;
      margin: 20px 60px;
      text-align: center;
      text-transform: uppercase; }
    a {
      text-decoration: none; }
    img {
      margin: 0; } } }

#block-home-expertise-vision {
  .container {
    height: 445px; }
  .content {
    ul {
      @include rem(font-size, 0);
      margin: 0;
      li {
        @include rem(font-size, 14px);
        display: inline-block;
        margin: 0 90px 0 0;
        text-align: center;
        vertical-align: top;
        width: 430px;
        &:last-child {
          margin-right: 0; }
        &:before {
          display: none; }
        h3 {
          @include rem(font-size, 24px);
          margin: 100px 0 30px 0; }
        p {
          @include rem(font-size, 14px);
          @include rem(line-height, 20px);
          margin: 30px 0;
          &.text-summary {
            height: 100px; } }
        .btn {
          background: none;
          border-top: 1px solid map-get($colors, secondary);
          height: auto;
          padding: 20px;
          margin: 0;
          min-width: 0;
          &:hover {
            background-color: map-get($colors, secondary);
            padding-left: 40px;
            padding-right: 40px; }
          &:before {
            display: none; } } } }
    img {
      margin: 0; } } }

#block-home-brands {
  .container {
    min-height: 900px; }
  .block-inner {
    padding-left: 360px; }
  .title {
    h2 {
      @extend .highlighted;
      @extend .text-center;
      span {
        @extend .highlighted; } } }
  .content {
    ul {
      @include rem(font-size, 0);
      li {
        @extend .clearfix;
        @include rem(font-size, 16px);
        border-bottom: 1px solid #e5e5e5;
        margin: 0;
        padding: 30px 0;
        &:last-child {
          border-bottom: 0; }
        &:before {
          display: none; }
        br {
          display: none; }
        img {
          display: inline-block;
          float: none;
          margin: 0;
          vertical-align: middle;
          width: 40%; }
        h3 {
          @include rem(font-size, 24px);
          @include rem(line-height, 28px);
          clear: none;
          display: inline-block;
          margin: 0;
          padding: 0 0 0 30px;
          vertical-align: middle;
          width: 60%; } } } } }

#block-home-commitments {
  .container {
    height: 375px; }
  .title {
    h2 {
      @extend .text-center;
      margin: 50px 0; } }
  .content {
    ul {
      @include rem(font-size, 0);
      margin: 0;
      padding: 0;
      li {
        @include rem(font-size, 16px);
        display: inline-block;
        margin: 0 45px;
        padding: 0;
        vertical-align: top;
        width: 120px;
        &:before {
          display: none; }
        .icon {
          @include rem(font-size, 0);
          &:before {
            @include rem(font-size, 80px);
            color: map-get($colors, secondary);
            display: inline-block;
            margin-bottom: 50px; } }
        .text {
          @include rem(font-size, 18px);
          display: inline-block;
          font-family: "Pluto W01 ExtraLight", Arial, Verdana, sans-serif;
          font-weight: bold; }
        a {
          display: block;
          height: 100%;
          text-decoration: none; } } } } }

#block-home-recruitment {
  .container {
    height: 542px; }
  .title {
    h2 {
      @extend .highlighted;
      @extend .text-center;
      margin: 100px 0 50px 0;
      span {
        @extend .highlighted; } } }
  .content {
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        &:before {
          display: none; } }
      &.links {
        @include rem(font-size, 0);
        margin: 100px 0;
        li {
          @include rem(font-size, 16px);
          display: inline-block;
          margin: 0 25px; } } } } }

#block-group-expertise-vision {
  background-color: #efeadf;
  border-bottom: 1px solid map-get($colors, secondary);
  box-shadow: 0 10px 10px rgba(#000, 0.15);
  z-index: 2;
  .container {
    height: 632px; }
  .block-inner {
    padding-left: 400px; }
  .title {
    h2 {
      @include rem(font-size, 24px);
      margin-top: 100px; } }
  .content {
    .text-summary {
      @include rem(font-size, 16px); } } }

#block-group-history {
  .container {
    height: 931px; }
  .block-inner {
    padding-right: 400px; }
  .title {
    h2 {
      @include rem(font-size, 24px);
      border-bottom: 1px solid map-get($colors, secondary);
      padding-bottom: 15px; } }
  .content {
    font-family: "Pluto W01 ExtraLight", Arial, Verdana, sans-serif;
    font-weight: bold;
    strong, b {
      font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif; }
    ul {
      @include rem(font-size, 0);
      margin: 0;
      padding: 0;
      li {
        @extend .clearfix;
        @include rem(font-size, 14px);
        @include rem(line-height, 18px);
        margin: 0;
        min-height: 150px;
        overflow: hidden;
        padding: 0 0 30px 0;
        position: relative;
        &:last-child {
          min-height: 0;
          padding-bottom: 0;
          &:after {
            display: none; } }
        &:after {
          background-color: map-get($colors, secondary);
          content: "";
          display: block;
          height: 100%;
          left: 34px;
          position: absolute;
          top: 0;
          width: 1px; }
        &:before {
          display: none; }
        > strong {
          background-color: map-get($colors, secondary);
          border-radius: 34px;
          color: map-get($colors, primary);
          float: left;
          font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif;
          font-weight: normal;
          line-height: 68px;
          height: 68px;
          position: relative;
          text-align: center;
          vertical-align: top;
          width: 68px;
          z-index: 2;
          &:after {
            border: 2px solid map-get($colors, primary);
            border-radius: 32px;
            content: "";
            display: block;
            left: 2px;
            height: 64px;
            position: absolute;
            top: 2px;
            width: 64px; }
          span {
            color: map-get($colors, primary);
            display: inline-block;
            line-height: normal;
            vertical-align: middle; } }
        > span {
          float: right;
          vertical-align: top;
          width: 400px; } } }
    .highlighted {
      @include rem(font-size, 18px); } } }

#block-group-statistics {
  border-bottom: 1px solid map-get($colors, secondary);
  border-top: 2px solid #dedede;
  box-shadow: 0 0 20px rgba(#000, 0.15);
  z-index: 2;
  .container {
    height: 480px; }
  .block-inner {
    padding-left: 400px; }
  .title {
    h2 {
      @include rem(font-size, 24px);
      border-bottom: 1px solid map-get($colors, secondary);
      padding-bottom: 15px; } } }

#block-group-dna {
  .container {
    height: 736px; }
  .block-inner {
    padding-right: 400px; }
  .title {
    h2 {
      @include rem(font-size, 24px);
      border-bottom: 1px solid map-get($colors, secondary);
      padding-bottom: 15px; } }
  .content {
    font-family: "Pluto W01 ExtraLight", Arial, Verdana, sans-serif;
    font-weight: bold;
    strong, b {
      font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif; }
    .highlighted {
      @include rem(font-size, 18px);
      font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif; } } }

#block-brands-gavottes {
  .container {
    height: 704px; }
  .block-inner {
    padding-left: 40px;
    padding-right: 400px; }
  .content {
    img {
      margin: 30px 0 0 0; }
    h2 {
      border-bottom: 1px solid map-get($colors, secondary);
      padding-bottom: 15px; } } }

#block-brands-traoumad {
  .container {
    height: 650px; }
  .block-inner {
    padding-left: 400px;
    padding-right: 40px; }
  .content {
    img {
      margin: 60px 0 0 0; }
    h2 {
      border-bottom: 1px solid map-get($colors, secondary);
      padding-bottom: 15px; } } }

#block-brands-expertise {
  .container {
    height: 467px; }
  .block-inner {
    padding-left: 180px;
    padding-right: 180px; }
  .title {
    h2 {
      @include rem(font-size, 24px);
      border-bottom: 1px solid map-get($colors, secondary);
      padding-bottom: 15px;
      margin-top: 120px;
      text-align: center; } }
  .content {
    p {
      margin-left: 50px;
      margin-right: 50px; } } }

#block-find-distribution {
  .container {
    height: 482px; }
  .block-inner {
    padding-right: 400px; }
  .title {
    h2 {
      @include rem(font-size, 24px);
      border-bottom: 1px solid map-get($colors, secondary);
      margin-top: 80px;
      padding-bottom: 15px; } }
  .content {
    ul {
      font-family: "Pluto W01 ExtraLight", Arial, Verdana, sans-serif;
      font-weight: bold;
      li {
        margin-bottom: 10px;
        margin-top: 10px; } } } }

#block-find-stores {
  border-bottom: 1px solid map-get($colors, secondary);
  box-shadow: 0 0 20px rgba(#000, 0.15);
  position: relative;
  z-index: 2;
  .block-inner {
    padding-left: 80px;
    padding-right: 80px; }
  .title {
    margin-left: 280px;
    h2 {
      @include rem(font-size, 24px);
      border-bottom: 1px solid map-get($colors, secondary);
      padding-bottom: 15px; } }
  .content {
    ul {
      margin: 0;
      padding: 0;
      li {
        @extend .clearfix;
        margin: 0 0 60px 0;
        padding: 0;
        position: relative;
        &:before {
          display: none; }
        > img {
          @include center;
          left: 40px;
          margin: 0;
          max-width: 190px; }
        > span {
          border-left: 1px solid map-get($colors, secondary);
          float: right;
          padding: 10px 40px;
          width: 560px; } } } } }

#block-find-international {
  .container {
    height: 529px; }
  .block-inner {
    padding-left: 40px;
    padding-right: 400px; }
  .title {
    h2 {
      @include rem(font-size, 24px);
      border-bottom: 1px solid map-get($colors, secondary);
      margin-top: 80px;
      padding-bottom: 15px; } }
  .content {
    font-family: "Pluto W01 ExtraLight", Arial, Verdana, sans-serif;
    font-weight: bold; } }

#block-commitments-sites {
  .container {
    height: 705px; }
  .block-inner {
    padding-right: 400px; }
  .title {
    h2 {
      @include rem(font-size, 24px);
      border-bottom: 1px solid map-get($colors, secondary);
      margin-top: 80px;
      padding-bottom: 15px; } } }

#block-commitments-products {
  box-shadow: 0 -5px 10px rgba(#000, 0.15);
  z-index: 2;
  .container {
    max-height: 750px;
    min-height: 725px; }
  .block-inner {
    padding-left: 400px; }
  .title {
    h2 {
      @include rem(font-size, 24px);
      border-bottom: 1px solid map-get($colors, secondary);
      margin-top: 80px;
      padding-bottom: 15px; } } }

#block-commitments-packages {
  box-shadow: 0 -5px 10px rgba(#000, 0.15);
  z-index: 2;
  .container {
    height: 668px; }
  .block-inner {
    padding-right: 400px; }
  .title {
    h2 {
      @include rem(font-size, 24px);
      border-bottom: 1px solid map-get($colors, secondary);
      margin-top: 80px;
      padding-bottom: 15px; } }
  .content {
    p, ul, ol {
      @include rem(line-height, 18px); } } }

#block-recruitment-policies {
  .container {
    height: 608px; }
  .block-inner {
    padding-right: 400px; }
  .title {
    h2 {
      border-bottom: 1px solid map-get($colors, secondary);
      margin-top: 80px;
      padding-bottom: 30px; } } }

#block-recruitment-offers {
  background-image: url("../img/block-recruitment-offers.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  border-top: 2px solid #dedede;
  box-shadow: 0 0 20px rgba(#000, 0.15);
  min-height: 400px;
  position: relative;
  z-index: 2;
  .title {
    h2 {
      border-bottom: 1px solid map-get($colors, secondary);
      padding-bottom: 15px; } }
  .content {
    .view-offers {
      @include rem(font-size, 0);
      margin-bottom: 30px; }
    .view-empty {
      @include rem(font-size, 16px); }
    .views-row {
      @include rem(font-size, 16px);
      background-color: #fef9ef;
      border: 1px solid #daba4e;
      display: inline-block;
      height: 280px;
      margin: 0 10px 10px 0;
      position: relative;
      vertical-align: top;
      width: 230px; }
    .views-field {
      @include rem(font-size, 12px);
      font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
      font-weight: bold;
      margin: 5px 15px;
      .views-label {
        color: map-get($colors, primary); }
      .field-content {
        color: map-get($colors, secondary);
        display: inline-block; } }
    .views-field-field-offer-date {
      @include rem(font-size, 14px);
      font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif;
      font-weight: normal;
      margin-top: 15px;
      .views-label {
        color: map-get($colors, secondary); } }
    .views-field-title {
      @include rem(font-size, 18px);
      border-bottom: 1px solid map-get($colors, secondary);
      font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif;
      font-weight: normal;
      margin-bottom: 15px;
      margin-top: 15px;
      padding-bottom: 15px;
      text-transform: uppercase;
      .field-content {
        color: map-get($colors, primary); } }
    .views-field-view-node {
      bottom: 20px;
      margin: 0;
      position: absolute;
      right: 0; } } }

#block-recruitment-spontaneous {
  .container {
    height: 430px; }
  .title {
    text-align: center;
    h2 {
      @extend .highlighted;
      margin: 80px 0 60px 0;
      span {
        @extend .highlighted; } } }
  .content {
    p {
      margin-left: 40px;
      margin-right: 40px; } }
  .footer {
    margin: 80px 0;
    text-align: center; } }

#block-medias-press {
  background-image: url("../img/block-medias-press.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  .container {
    min-height: 650px; }
  .title {
    h2 {
      margin-top: 80px;
      text-align: center; } }
  .content {
    .item-0,
    .owl-item {
      height: 400px;
      padding: 0 15px;
      position: relative; }
    .views-field-title {
      h2 {
        @include rem(font-size, 20px);
        @include rem(line-height, 28px);
        margin-bottom: 0; } }
    .views-field-field-press-date {
      @extend .text-summary;
      @include rem(font-size, 18px);
      margin: 5px 0; }
    .views-field-body {
      @include rem(font-size, 14px);
      @include rem(line-height, 20px);
      font-family: "Pluto W01 ExtraLight", Arial, Verdana, sans-serif;
      font-weight: bold;
      margin: 30px 0; }
    .views-field-field-press-file {
      bottom: 0;
      margin-bottom: 30px;
      position: absolute;
      right: 0;
      .btn {
        @include rem(line-height, 40px);
        padding-bottom: 0;
        padding-top: 0;
        .text {
          display: inline-block;
          line-height: normal;
          vertical-align: middle; } } } } }

#block-medias-newsletters {
  background: #fef9ef url("../img/block-medias-newsletters.jpg") no-repeat center center;
  .container {
    height: 650px; }
  .title {
    h2 {
      margin-top: 80px;
      text-align: center; } }
  .content {
    p {
      margin: 0 0 80px 0;
      text-align: center; }
    .owl-item {
      background-color: #f5f5f5;
      border: 1px solid #e5e5e5;
      border-bottom: 2px solid map-get($colors, secondary);
      margin: 0 8px;
      max-width: 189px;
      position: relative;
      img {
        margin: 0; } }
    .views-field-field-newsletter-date {
      @include rem(font-size, 14px);
      color: map-get($colors, primary);
      margin: 10px; }
    .views-field-uri {
      background-color: rgba(#000, 0.7);
      left: 0;
      height: 218px;
      opacity: 0;
      position: absolute;
      transform: scale(0.9);
      text-align: center;
      transition: all 0.2s ease-in-out;
      top: 0;
      width: 100%;
      html.no-touch & {
        &:hover {
          opacity: 1;
          transform: scale(1); } }
      a {
        @include rem(font-size, 12px);
        color: #fff;
        display: block;
        line-height: 218px;
        font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
        text-decoration: none;
        text-transform: uppercase;
        .text {
          background-color: #d5b13e;
          background-image: url("../img/paper2.png");
          background-position: center center;
          background-repeat: repeat-y;
          color: #fff;
          display: inline-block;
          line-height: normal;
          padding: 15px;
          vertical-align: middle; } } }
    .views-field-nothing {
      @include rem(font-size, 10px);
      color: #777;
      margin: 10px;
      text-transform: uppercase; } } }

#block-medias-newsletters-form {
  background: #fef9ef url("../img/block-medias-newsletters.jpg") no-repeat center 470px;
  .container {
    height: 470px; }
  .content {
    form {
      margin: 40px auto;
      width: 570px; }
    .form-item {
      label {
        width: 130px; } }
    .description {
      @include rem(font-size, 12px);
      margin-left: 180px;
      margin-top: 15px; } } }

#block-medias-mediatheque {
  background-image: url("../img/block-medias-mediatheque.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  .container {
    min-height: 860px; }
  .title {
    h2 {
      margin-top: 80px;
      text-align: center; } }
  .content {
    a {
      display: block;
      text-decoration: none;
      &.bef-toggle {
        padding: 8px 10px; } }
    img {
      margin: 0;
      vertical-align: top; }
    .views-exposed-widget {
      @include rem(font-size, 0);
      margin: 0 0 30px 0;
      > .form-item {
        margin: 0; }
      label, div, .bef-toggle {
        @include rem(font-size, 14px);
        color: #fff;
        display: inline-block;
        font-weight: normal;
        margin: 0; }
      .bef-checkboxes {
        @include rem(font-size, 0px);
        .form-item {
          cursor: pointer;
          display: inline-block;
          margin: 0 10px 10px 0;
          padding: 8px 10px;
          text-decoration: none;
          transition: all 0.2s ease-in-out;
          &.highlight,
          html.no-touch &:hover {
            background-color: #2f3949; }
          label {
            cursor: pointer;
            margin: 0; }
          input {
            display: none; } } } }
    .view-content {
      @extend .clearfix;
      @include rem(font-size, 0); }
    .views-row {
      @include rem(font-size, 16px);
      border: 10px solid #d2d5da;
      display: inline-block;
      margin: 10px 10px 0 0;
      transition: all 0.2s ease-in-out;
      html.no-touch &:hover {
        border-color: map-get($colors, secondary); } }
    .views-submit-button {
      display: none; } } }

#block-news {
  background-color: #efebe3;
  overflow-x: hidden;
  .container {
    background: none !important;
    width: auto !important; }
  .block-inner {
    float: none !important;
    margin: 0 !important;
    width: auto !important; }
  .content {
    .view-news {
      .views-row {
        @extend .clearfix;
        color: map-get($colors, primary);
        height: 650px;
        padding: 0 15%;
        position: relative;
        z-index: 1;
        &:nth-child(odd) {
          background-color: map-get($colors, primary);
          color: #fff;
          padding-right: 50%;
          h2, h3, h4, h5,
          p,
          a, span,
          ul, ol, li {
            color: #fff; }
          img {
            right: 50%;
            margin-right: -960px !important; } }
        &:nth-child(even) {
          padding-left: 50%;
          img {
            left: 50%;
            margin-left: -960px !important; } }
        img {
          max-width: none;
          margin: 0;
          position: absolute;
          top: 0;
          z-index: -1; } }
      .views-field-title {
        @include rem(font-size, 24px);
        border-bottom: 1px solid map-get($colors, secondary);
        margin-top: 160px;
        padding-bottom: 15px; }
      .views-field-body {
        @extend .text-summary;
        margin-bottom: 30px; }
      .views-field-created {
        @include rem(font-size, 12px);
        float: left;
        font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
        margin-bottom: 30px; }
      .views-field-view-node {
        @include rem(font-size, 12px);
        float: right;
        font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
        margin-bottom: 30px;
        text-transform: uppercase;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline; } } }
      .item-list {
        @include container(960px);
        .pager {
          margin: 0; } } } } }

#block-news-newsletter {
  background-color: #efebe3;
  position: relative;
  &:before {
    background-color: map-get($colors, primary);
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    margin-left: -255px;
    position: absolute;
    top: 0;
    width: 510px; } }

#block-recruitment-jobform {
  html.page-node-45 &,
  html.page-node-46 & {
    .title {
      display: none; } }
  .block-inner {
    padding-right: 310px; }
  .title {
    text-align: center;
    h2 {
      @extend .highlighted;
      span {
        @extend .highlighted; } } }
  .content {
    .ajax-processed {
      display: none; } } }

#block-sitemap-main-menu {
  .content {
    li {
      br {
        content: " ";
        display: inline-block;
        padding: 0 4px 0 0; } }
    a {
      &:before {
        display: none; } } } }

// ==================================================
// Footer
// ==================================================

#footer {
  background-color: #fff;
  box-shadow: 0 0 20px rgba(#000, 0.15);
  color: #000;
  position: relative;
  z-index: 2;
  &:before {
    background-color: map-get($colors, secondary);
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 100%; }
  > .container {
    min-height: $footer_height;
    a {
      color: #000; } } }

#block-footer-main-menu {
  margin-bottom: 50px;
  margin-top: 50px;
  .content {
    ul {
      @include rem(font-size, 0);
      color: #3c495d;
      margin: 0;
      li {
        @include rem(font-size, 14px);
        @include rem(line-height, 16px);
        display: inline-block;
        font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
        font-weight: bold;
        margin: 0 10px 0 0;
        text-transform: uppercase;
        vertical-align: top;
        width: 150px;
        &.menu-contact {
          display: none; }
        &:before {
          display: none; }
        &:nth-child(5n),
        &.last {
          margin-right: 0; }
        > a {
          min-height: 32px; }
        li {
          @include rem(font-size, 12px);
          @include rem(line-height, 20px);
          display: block;
          font-family: "Pluto W01 ExtraLight", Arial, Verdana, sans-serif;
          font-weight: bold;
          margin: 0;
          text-transform: none;
          width: auto;
          a {
            min-height: 0;
            &:hover {
              color: map-get($colors, primary);
              text-decoration: underline; } } } }
      ul {
        margin-top: 15px; }
      a {
        color: #3c495d;
        display: block;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        &:hover {
          color: map-get($colors, secondary); } } } } }

#block-footer-secondary-menu {
  .content {
    ul {
      @include rem(font-size, 0);
      @include rem(line-height, 50px);
      margin: 0;
      li {
        @include rem(font-size, 11px);
        color: #3c495d;
        display: inline-block;
        font-family: Arial, Verdana, sans-serif;
        margin: 0;
        &.first {
          &:before {
            display: none; } }
        &:before {
          color: map-get($colors, secondary);
          content: "|";
          display: inline-block;
          margin: 0 10px; }
        &.menu-copyright {
          cursor: text;
          display: none;
          a {
            cursor: text;
            pointer-events: none;
            text-decoration: none; } } }
      a {
        color: #3c495d;
        text-decoration: none;
        &:hover {
          text-decoration: underline; } } } } }

#block-footer-brands-menu {
  .content {
    text-align: right;
    ul {
      @include rem(font-size, 0);
      @include rem(line-height, 50px);
      margin: 0;
      li {
        @include rem(font-size, 12px);
        border-top: 1px solid map-get($colors, secondary);
        color: map-get($colors, secondary);
        display: inline-block;
        font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
        margin: 0 10px 0 0;
        text-align: center;
        text-transform: uppercase;
        width: 150px;
        &.last {
          margin-right: 0; }
        &:before {
          display: none; } }
      a {
        color: map-get($colors, secondary);
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        &:hover {
          color: map-get($colors, primary); } } } } }
