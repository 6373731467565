// ==================================================
// Forms
// ==================================================

form {
  @extend .clearfix; }

.form-required {
  color: map-get($colors, primary); }

.form-item {
  @extend .clearfix;
  margin: 15px 30px;
  label {
    @include rem(font-size, 16px);
    color: #444;
    display: inline-block;
    margin-right: 15px;
    text-align: right;
    vertical-align: middle;
    width: 200px;
    &.option {
      @include rem(font-size, 16px);
      display: inline;
      margin-right: 0;
      text-align: left;
      width: auto; } }
  .form-item {
    &.form-type-password {
      margin: 0; }
    &.confirm-parent {
      margin-top: 15px; } } }

.form-type-password {
  &.confirm-parent {
    margin-bottom: 15px;
    margin-top: 15px; } }

.form-radios {
  display: inline-block;
  vertical-align: top;
  .form-item {
    display: block;
    margin: 0 0 5px 0;
    vertical-align: middle; } }

.form-text {
  @include rem(font-size, 16px);
  appearance: none;
  background-color: #f9f9f9;
  border: 1px solid rgba(#bcbcbc, 0.4);
  border-radius: 0;
  display: inline-block;
  font-family: "Pluto W04 Regular", Arial, sans-serif;
  padding: 13px;
  vertical-align: middle;
  width: 360px;
  &[readonly="readonly"] {
    background-color: #eee; } }

.form-textarea {
  @include rem(font-size, 16px);
  appearance: none;
  background-color: #f9f9f9;
  border: 1px solid rgba(#bcbcbc, 0.4);
  border-radius: 0;
  display: inline-block;
  font-family: "Pluto W04 Regular", Arial, sans-serif;
  min-height: 200px;
  padding: 13px;
  resize: vertical;
  vertical-align: middle;
  width: 360px; }

.form-managed-file {
  display: inline-block;
  max-width: 370px;
  vertical-align: middle; }

.form-select {
  @include rem(font-size, 16px);
  font-family: "Pluto W04 Regular", Arial, sans-serif;
  padding: 8px;
  width: 360px; }

.form-submit:not(.ajax-processed) {
  @include rem(font-size, 12px);
  @include rem(line-height, 40px);
  appearance: none;
  background-color: map-get($colors, primary);
  background-image: none;
  border: 0;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  float: right;
  font-family: "Pluto W01 Bold", Arial, sans-serif;
  margin: 30px;
  min-height: 40px;
  padding: 0 30px;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out;
  .no-touch &:hover {
    background-color: map-get($colors, secondary); } }

.fieldset-description {
  @include rem(font-size, 14px);
  @include rem(line-height, 20px);
  color: #aaa;
  margin: 15px 30px; }

.fieldset-legend {
  color: #444;
  margin: 15px 30px; }

.description,
.first-info,
.info {
  color: #aaa; }

.description {
  @include rem(font-size, 12px);
  margin: 10px 0 0 220px; }

div.password-strength,
div.password-confirm {
  display: none; }

.webform-component-markup {
  p {
    @include rem(font-size, 10px);
    @include rem(line-height, 14px);
    color: #aaa;
    width: 580px; }
  a {
    color: #aaa; } }

#autocomplete {
  border: 0;
  box-shadow: 0 0 3px #bbb;
  ul,
  li {
    margin: 0; }
  .reference-autocomplete {
    cursor: pointer;
    padding: 0 10px;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: #efefef; } } }

html.js {
  input.form-autocomplete {
    background-image: url("../img/throbber.gif");
    background-position: 98% 11px;
    &.throbbing {
      background-position: 98% -29px; } } }

.g-recaptcha {
  margin: 30px 0 0 250px; }
