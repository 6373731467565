a.btn,
.btn a {
  @include rem(font-size, 12px);
  appearance: none;
  background-color: map-get($colors, secondary);
  background-image: url("../img/paper.png");
  background-position: right top;
  background-repeat: repeat-y;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  height: 40px;
  line-height: normal;
  min-width: 120px;
  margin: 0 0 0 20px;
  padding: 14px 22px 14px 7px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  &:before {
    background: transparent url("../img/btn-before.png") no-repeat 0 0;
    content: "";
    display: block;
    left: -20px;
    position: absolute;
    height: 40px;
    top: 0;
    width: 20px; }
  html.no-touch & {
    &:hover {
      min-width: 160px;
      padding-left: 21px;
      padding-right: 44px; } } }
a.btn-primary,
.btn-primary {
  background-color: map-get($colors, primary);
  &:before {
    background-image: url("../img/btn-primary-before.png"); } }

a.btn-facebook {
  @extend .font-locma;
  @extend .icon-facebook;
  @include rem(font-size, 18px);
  background-color: #FFFFFF;
  border-radius: 20px;
  color: #3c495d;
  display: inline-block;
  height: 40px;
  line-height: 42px;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  vertical-align: middle;
  width: 40px;
  &::before {
    color: #3c495d; }
  &:hover {
    background-color: #d3b03d; } }

a.btn-facebook-dark {
  background-color: #3c495d;
  color: #FFFFFF;
  &::before {
    color: #FFFFFF; }
  &:hover {
    background-color: #d3b03d; } }
