// ==================================================
// Typography rules
// Fonts.com: Pluto W01 ExtraLight, Pluto W04 Regular, Pluto W01 Bold
// Local: CACPinafore Regular
// ==================================================

@import url("//fast.fonts.net/cssapi/af1cbc1c-3c70-4f9b-b323-2bd3c25c8b90.css");

@font-face {
  font-family: "CACPinafore Regular";
  src: url("../fonts/cacpinaf/cacpinaf-webfont.eot");
  src: url("../fonts/cacpinaf/cacpinaf-webfont.eot?#iefixnq5m6t") format("embedded-opentype"), url("../fonts/cacpinaf/cacpinaf-webfont.woff") format("woff"), url("../fonts/cacpinaf/cacpinaf-webfont.ttf") format("truetype"), url("../fonts/cacpinaf/cacpinaf-webfont.svg#cacpinaf-webfont") format("svg");
  font-weight: normal;
  font-style: normal; }

html {
  font-size: 62.5%; // 1rem = 10px
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0.1);
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  @include rem(font-size, 16px);
  font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif; }

a {
  color: map-get($colors, primary);
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: map-get($colors, primary);
    text-decoration: none; } }

b, strong {
  font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
  font-weight: normal; }

i, em {
  font-style: italic; }

u {
  @extend .text-underline; }

img {
  margin: 5px 30px 15px 30px;
  &.align-left {
    margin-left: 0; }
  &.align-right {
    margin-right: 0; } }

h1 {
  @extend .clear-both;
  @include rem(font-size, 30px);
  border-bottom: 1px solid map-get($colors, secondary);
  color: map-get($colors, primary);
  margin: 30px 0;
  padding-bottom: 30px;
  i, em,
  b, strong,
  span {
    color: #3c495d; }
  strong {
    font-weight: normal; }
  em {
    font-style: normal; } }

h2 {
  @extend .clear-both;
  @include rem(font-size, 24px);
  color: map-get($colors, primary);
  margin: 40px 0;
  &:before {
    margin-right: 10px; }
  i, em,
  b, strong,
  span {
    color: #3c495d; }
  strong {

    font-weight: normal; }
  em {
    font-style: normal; }
  a {
    &:hover {
      text-decoration: none; } } }

h3 {
  @extend .clear-both;
  @include rem(font-size, 22px);
  color: map-get($colors, primary);
  margin: 40px 0;
  &:before {
    margin-right: 10px; }
  a {
    &:hover {
      text-decoration: none; } } }

h4 {
  @extend .clear-both;
  @include rem(font-size, 20px);
  color: #3c495d;
  margin: 30px 0;
  a {
    &:hover {
      text-decoration: none; } } }

h5 {
  @extend .clear-both;
  @include rem(font-size, 18px);
  color: #3c495d;
  margin: 20px 0;
  a {
    &:hover {
      text-decoration: none; } } }

p {
  @include rem(line-height, 24px);
  color: #3c495d;
  margin: 15px 0; }

dt,
dd {
  @include rem(line-height, 24px);
  color: #3c495d;
  margin: 15px 30px; }

blockquote {
  @include rem(font-size, 18px);
  @include rem(line-height, 27px);
  background-color: #ebebeb;
  border-bottom: 2px solid #aaa;
  color: #555454;
  font-style: italic;
  overflow: hidden;
  padding: 20px 85px;
  position: relative;
  margin: 30px 0;
  &:after {
    @include rem(font-size, 80px);
    bottom: 25px;
    color: #ccc;
    content: "»";
    position: absolute;
    right: 25px; }
  &:before {
    @include rem(font-size, 80px);
    color: #ccc;
    content: "«";
    left: 25px;
    position: absolute;
    top: 25px; }
  p {
    @include rem(font-size, 18px);
    @include rem(line-height, 27px);
    color: #555454;
    margin: 15px; } }

ul {
  @include rem(line-height, 24px);
  color: #3c495d;
  margin: 15px 0;
  &.dots {
    li {
      &:before {
        content: "•"; } } }
  &.dashed {
    li {
      &:before {
        content: "-"; } } }
  li {
    @extend .icon-tick;
    list-style: none;
    margin: 0 30px;
    &:before {
      @extend .font-locma;
      @include rem(font-size, 12px);
      color: map-get($colors, secondary);
      margin-right: 10px; }
    li {
      margin: 0 45px; } } }

ol {
  @include rem(line-height, 24px);
  color: #3c495d;
  margin: 15px 0;
  li {
    list-style: decimal;
    margin: 0 40px;
    li {
      margin: 0; } } }

hr {
  @extend .clear-both;
  background-color: #7f7f7f;
  border: 0;
  color: #7f7f7f;
  height: 1px;
  margin: 15px 0; }

sub {
  @include rem(font-size, 11px);
  vertical-align: sub; }

sup {
  @include rem(font-size, 11px);
  vertical-align: super; }

table {
  margin: 30px 0;
  table-layout: fixed;
  text-align: center;
  width: 100%;
  &.no-border {
    thead {
      th {
        border: 0; } }
    tbody {
      td {
        border: 0; } } }
  &.no-striped {
    thead {
      th {
        background-color: transparent; } }
    tbody {
      tr {
        &.odd,
        &:nth-child(odd) {
          td {
            background-color: transparent; } }
        &.even,
        &:nth-child(even) {
          td {
            background-color: transparent; } } } } }
  thead {
    th {
      background-color: map-get($colors, primary);
      border: 1px solid #e4e4e4;
      color: #fff;
      font-family: "Pluto W04 Regular", Arial, Verdana, sans-serif;
      font-weight: bold;
      padding: 20px 40px;
      text-transform: uppercase; } }
  tbody {
    tr {
      &.odd,
      &:nth-child(odd) {
        td {
          background-color: #fff; } }
      &.even,
      &:nth-child(even) {
        td {
          background-color: #f5f5f5; } }
      td {
        background-color: #fff;
        border: 1px solid #e4e4e4;
        color: #555454;
        padding: 20px 40px; } } } }
