// ==================================================
// Others
// ==================================================

// Pager
.pager {
  @include rem(font-size, 0);
  margin: 20px 10px;
  padding: 15px;
  text-align: center;
  li {
    @include rem(font-size, 18px);
    color: map-get($colors, primary);
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 0;
    text-align: center;
    transition: all 0.2s ease-in-out;
    &:before {
      display: none; }
    &.pager-current {
      font-family: "Pluto W01 Bold", Arial, Verdana, sans-serif;
      font-weight: bold; }
    a {
      color: map-get($colors, primary);
      display: block;
      text-decoration: none; } } }

// Owl Carousel
.owl-carousel {
  padding: 0 69px;
  position: relative; }
.owl-theme {
  .owl-controls {
    .owl-buttons {
      div {
        @include rem(font-size, 0);
        background: none;
        border: 2px solid map-get($colors, secondary);
        border-radius: 0;
        height: 45px;
        margin: -32px 0 0 0;
        opacity: 1;
        padding: 0;
        position: absolute;
        top: 50%;
        width: 45px;
        &.owl-prev {
          border-bottom: 0;
          border-right: 0;
          left: 10px;
          transform: rotate(-45deg); }
        &.owl-next {
          border-bottom: 0;
          border-left: 0;
          right: 10px;
          transform: rotate(45deg); } } } } }

// Shadowbox
#sb-container {
  img {
    margin: 0; } }

#sb-overlay {
  height: 100% !important;
  min-height: auto; }

// EU Cookie Compliance
#sliding-popup {
  font-family: Arial, Verdana, sans-serif;
  .popup-content {
    #popup-text {
      p {
        margin: 5px 0; } }
    #popup-buttons {
      button {
        margin-top: 7px; } } } }

// Job - Travailleur handicapé
.handicap {
  margin-left: 15px; }
